import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, lang }) => {

    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        defaultTitle,
        defaultDescription,
        siteUrl
    } = site.siteMetadata

    if (title !== '') {
        title = title + ' | ' + defaultTitle
    } else {
        title = defaultTitle
    }

    const seo = {
        title: title,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname}`
    }

    return (
        <Helmet
            title={seo.title}
        >
            <meta name="description" content={seo.description} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            <html lang={lang} />
        </Helmet>
    )
}

export default SEO

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    lang: PropTypes.string,
    isIndex: PropTypes.bool
}

SEO.defaultProps = {
    title: null,
    description: null,
    lang: `fr`,
    isIndex: false
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl: url
            }
        }
    }
`
