import React from "react"
import PropTypes from "prop-types"
import BackToTop from "../components/back-to-top/back-to-top"
import Seo from "../components/seo/seo"
import Navbar from "../components/navbar/navbar"
import Footer from "../components/footer/footer"
import * as styles from "./layout.module.scss"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const Layout = ({ seoTitle, seoDescription, ctx, breadcrumbDisableLink, breadcrumbCustomLabel, backgroundColor, children }) => {

    const disableLinks = [breadcrumbDisableLink]
    let crumbs = ctx.breadcrumb.crumbs

    return (
        <div style={{backgroundColor: backgroundColor}}>
            <Seo
                title={seoTitle}
                description={seoDescription}
                lang={'fr'}
            />
            <div className={styles.layout}>
                <header className={styles.layout__header}>
                    <div className={styles.layout__headerInner}>
                        <Navbar>
                            <Breadcrumb
                                crumbs={crumbs}
                                crumbSeparator=" > "
                                disableLinks={disableLinks}
                                crumbLabel={breadcrumbCustomLabel}
                            />
                        </Navbar>
                    </div>
                </header>
                <main className={styles.layout__main}>
                    <div className={styles.layout__mainInner}>
                        {children}
                    </div>
                </main>
                <footer className={styles.layout__footer}>
                    <div className={styles.layout__footerInner}>
                        <Footer/>
                    </div>
                </footer>
                <BackToTop/>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
