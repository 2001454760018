import React, {useState, useEffect} from "react"
import * as styles from "./navbar.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import {Link, navigate} from "gatsby"
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import {isLoggedIn, logout} from "../../services/auth"
import {Menu} from "@material-ui/core"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import AccountBoxIcon from '@material-ui/icons/AccountBox'

const Navbar = (props) => {

    const [isLogged, setIsLogged] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        if (isLoggedIn()) {
            setIsLogged(true)
        } else {
            setIsLogged(false)
        }
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handlerClickLogout = () => {
        logout(() => navigate(`/login`))
    }

    const handlerClickProfile = () => {
        setAnchorEl(null)
        navigate(`/app/profile`)
    }

    return(
        <div className={styles.navbar}>
            <div className={styles.navbar__left}>
                <Link to={'/'}>
                    <StaticImage src={'../../images/logo.png'} alt={'Arbres et compagnie'} placeholder={'blurred'} />
                </Link>
            </div>
            <div className={styles.navbar__middle}>{props.children}</div>
            <div className={styles.navbar__right}>
                {isLogged && (
                    <div>
                        <button aria-controls="profileMenu" aria-haspopup="true" className={styles.navbar__btnLogin + ' ' + styles.navbar__btnLoginActive} onClick={handleClick}>
                            <AccountCircleIcon/>
                        </button>
                        <Menu
                            id="profileMenu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                            placement={'bottom'}
                            className={'menuProfile'}
                        >
                            <div>
                                <button onClick={handlerClickProfile}>
                                    <div>
                                        <AccountBoxIcon/>
                                    </div>
                                    <div>
                                        Mon compte
                                    </div>
                                </button>
                            </div>
                            <div>
                                <button onClick={handlerClickLogout}>
                                    <div>
                                        <ExitToAppIcon/>
                                    </div>
                                    <div>
                                        Se déconnecter
                                    </div>
                                </button>
                            </div>
                        </Menu>
                    </div>
                )}
                {!isLogged && (
                    <Link to={'/login'} className={styles.navbar__btnLogin} title="Connexion"><AccountCircleIcon/></Link>
                )}
            </div>
        </div>
    )
}

export default Navbar
