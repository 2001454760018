import React, {useCallback, useEffect, useState} from 'react'
import * as styles from "./back-to-top.module.scss"
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const BackToTop = () => {

    const [showScroll, setShowScroll] = useState(null)

    const checkScrollTop = useCallback(
        () => {
            const headerHeight = 500

            if (!showScroll && window.pageYOffset > headerHeight) {
                setShowScroll(true)
            } else if (showScroll && window.pageYOffset <= headerHeight) {
                setShowScroll(false)
            }
        },
        [showScroll],
    );

    useEffect(
        () => {
            window.addEventListener('scroll', checkScrollTop)
            return () => window.removeEventListener('scroll', checkScrollTop)
        },
        [checkScrollTop],
    );

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    let classes = styles.backToTop

    if (showScroll) {
        classes += ' ' + styles.backToTop__show
    }

    return (
        <button className={classes} onClick={scrollTop} onKeyDown={scrollTop}>
            <ArrowUpwardIcon/>
        </button>
    );
}

export default BackToTop
