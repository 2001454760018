import React from "react"
import * as styles from "./footer.module.scss"

const Footer = () => {

    return (
        <div className={styles.footer}>
            ©{new Date().getFullYear()} Arbres et Compagnie
        </div>
    )
}

export default Footer
