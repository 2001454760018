import axios from 'axios'

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
    isBrowser() && window.localStorage.getItem("gatsbyUser")
        ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
        : {}

const setUser = user =>
    window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({email, password}) => {

    return axios.get(process.env.GATSBY_API_URL + '/auth-frontend', {
        params: {
            email: email,
            password: password
        }
    })
        .then(function (response) {
            setUser({
                id: response.data.id,
                name: response.data.name,
                email: response.data.email,
                jwt: response.data.confirmationToken
            })
            return response
        })
        .catch(function (error) {
            console.log(error)
        })

}

export const isLoggedIn = () => {
    const user = getUser()
    return !!user.email
}

export const logout = callback => {
    setUser({})
    callback()
}
